.sus-welcome {
  position: relative;
  .sus-welcome__text {
    padding: 25px;
    background: rgba(black, 0.5);
    width: 100%;
    max-width: 770px;
    position: relative;
    z-index: 3;
  }
  .sus-welcome__image {
    margin-top: -200px;
    position: relative;
    z-index: 1;
    filter: brightness(0.5);
    transition: all 0.3s ease-in-out;
    float: right;
    &:hover {
      filter: brightness(1);
    }
  }
  .sus-welcome-wires {
    position: absolute;
    left: 10%;
    top: 90%;
  }

  @include mq($until: xl) {
    .sus-welcome__text {
      max-width: 600px;
    }

    .sus-welcome__image {
      max-width: 600px;
    }
  }

  @include mq($until: lg) {
    .sus-welcome__title {
      text-align: center;
    }

    .sus-welcome__text {
      max-width: 100%;
    }

    .sus-welcome__image {
      margin-top: 20px;
      max-width: 100%;
      width: 100%;
      float: none;
    }

    .sus-welcome-wires {
      max-width: 100%;
      left: 0;
      overflow: hidden;
    }
  }

  @include mq($until: md) {
    .sus-welcome__text {
      padding: 10px;
    }
  }
}
