.sus-product {
  display: flex;
  margin-bottom: 50px;
  margin-top: -40px;

  &__image {
    flex: 1;
    margin-right: 80px;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__details {
    flex: 1;

    h1 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    select {
      margin: 5px 0 50px 0;
      padding: 5px 15px;
    }
  }

  &__price {
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 50px;
  }

  &__description {
    margin-bottom: 50px;
  }

  &__checkout {
    font-size: 1.2rem;
    margin-top: 50px;
    a {
      color: white;
      font-weight: bold;
      text-decoration: none;
    }
  }

  @include mq($until: lg) {
    &__image {
      margin-right: 25px;
    }
  }

  @include mq($until: md) {
    display: block;

    &__image {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}
