.sus-latest-release {
    position: relative;
    padding-top: 65px;
    .sus-latest-release__video-wrap {
        position: relative;
        height: 1px;
        padding-top: 56.5%;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
        &:hover {
            opacity: 1; }
        iframe {
            width: 100%;
            height: 100%;
            top: 0;
            position: absolute; } }
    .sus-latest-release__video-wrap--focus {
        opacity: 1; } }
