.sus-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba($color__black, 0.9);
  z-index: 400;
  padding: 5px;
  .sus-nav__items {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .sus-nav__item a {
    text-decoration: none;
    color: white;
    padding: 20px 30px;
    font-family: $font__serif;
    text-transform: uppercase;
    letter-spacing: 6px;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 0.6rem;
    position: relative;
    transition: all 0.3s ease-in-out;
    &::after {
      position: absolute;
      content: attr(data-text);
      left: 31px;
      top: 21px;
      margin: auto;
      mix-blend-mode: screen;
      color: rgb(0, 255, 0);
      width: 100%;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    &::before {
      position: absolute;
      content: attr(data-text);
      left: 29px;
      top: 19px;
      right: 0;
      bottom: 0;
      margin: auto;
      mix-blend-mode: screen;
      color: rgb(255, 0, 0);
      width: 100%;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      color: rgb(0, 0, 255);
      &::after {
        opacity: 1;
      }
      &::before {
        opacity: 1;
      }
    }
  }
  .sus-nav__logo {
    margin: 0 70px;
  }
  #sus-view-cart {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50px;
    margin: auto;
    height: 30px;
    line-height: 30px;
    font-size: 1.4rem;
    color: white;
    text-decoration: none;
    .sus-view-cart__count {
      position: absolute;
      right: -13px;
      top: -5px;
      font-size: 0.6rem;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: $color__red--dark;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
    .sus-view-cart__count--hidden {
      opacity: 0;
    }
  }

  .sus-nav-burger {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    height: 50px;
    color: white;
    text-transform: uppercase;
    font-family: $font__serif;
    letter-spacing: 4px;
    background: none;
    outline: 0;
    border: 0;
    font-size: 0.6rem;
    display: none;
    &__burga {
      width: 25px;
      height: 3px;
      background: white;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      &:after,
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
      }

      &:before {
        top: -8px;
      }

      &:after {
        top: 8px;
      }
    }
  }

  @include mq($until: lg) {
    .sus-nav-burger {
      display: block;
    }

    .sus-nav__item {
      display: none;
    }
  }

  @include mq($until: sm) {
    .sus-nav-burger {
      color: transparent;
    }
  }
}

.sus-offscreen-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba($color__red--dark, 0.9);
  color: white;
  text-align: center;
  padding: 50px 15px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 40px;
      display: block;
      a {
        font-weight: bold;
        font-size: 1.4rem;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-family: $font__serif;
        letter-spacing: 10px;
      }
    }
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    color: white;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.3rem;
  }
}
