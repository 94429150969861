.sus-category-list {
  .sus-category-list__items {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin: auto;
    li {
      display: flex;
      align-items: center;
      padding: 3px 10px;
    }
    a {
      color: white;
      text-decoration: none;
      font-family: serif;
      letter-spacing: 4px;
      text-transform: uppercase;
      font-size: 0.7rem;
    }
    li.current {
      background: $color__red--dark;
    }

    @include mq($until: md) {
      display: none;
    }
  }
}

.sus-category {
  &__select {
    display: none;

    select {
      width: 100%;
      max-width: 400px;
      padding: 10px 20px;
    }

    @include mq($until: md) {
      display: block;
    }
  }
}
