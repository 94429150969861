.sus-page-header {
  text-align: center;
  max-width: 800px;
  margin: auto;
  margin-bottom: 50px;
  .sus-page-header__text {
    line-height: 1.2rem;
    opacity: 0.9;
  }
}
.sus-page-header--negative {
  margin-top: -100px;
}
