.sus-banner {
  background-image: url("https://grumble.dev/sus/background-image.jpg");
  background-size: cover;
  background-position: 50% 20%;
  width: 100%;
  height: 100vh;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(rgba(black, 0), rgba(black, 1));
  }
  &__video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &__video--playing {
    opacity: 1;
  }
  &--small {
    max-height: 700px;
    background-position: 50% 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;

    @include mq($until: lg) {
      max-height: 550px;
    }

    @include mq($until: md) {
      max-height: 300px;
    }
  }

  &--sub {
    max-height: 400px;
    background-position: 50% 10%;
    opacity: 0.7;
  }
}
