.sus-profile {
  display: flex;
  position: relative;
  text-align: center;
  .sus-profile__image {
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 80%;
      background: linear-gradient(rgba(black, 0), rgba(black, 1));
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  h3 {
    transform: translateX(15px);
  }

  &__details {
    position: absolute;
    width: 100%;
    bottom: 20px;
    padding: 20px;
  }

  @include mq($until: lg) {
    h3 {
      transform: translateY(10px) !important;
    }
    img {
      max-width: 300px;
    }
  }
}
