.sus-product-list {
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  &__item {
    text-align: center;
    transition: all 0.3s ease-in-out;
    a {
      color: $color__white;
      text-decoration: none;
      font-family: $font__serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 0.7rem;
    }
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 1 !important;
    }
  }
  .sold {
    opacity: 0.3;
  }
  &__image {
    padding-top: 130%;
    background-size: cover;
    background-position: 50% 50%;
  }
  &__product-details {
    padding: 15px;
  }
  &__product-name {
    margin-top: 15px;
  }
  &__product-price {
    margin-top: 5px;
  }

  &:hover {
    .sus-product-list__item {
      opacity: 0.3;
    }
  }

  @include mq($until: lg) {
    &__items {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include mq($until: md) {
    &__items {
      grid-template-columns: 1fr;
    }

    &__image {
      padding-top: 80%;
    }
  }
}
