@import "~vue-snotify/styles/material";
@import "vendor/normalize.css";
@import "config";
@import "components/banner";
@import "components/nav";
@import "components/typography";
@import "components/welcome";
@import "components/latest-release";
@import "components/featured-products";
@import "components/products";
@import "components/buttons";
@import "components/gig-list";
@import "components/page-header";
@import "components/profile";
@import "components/category-list";
@import "components/pagination";
@import "components/alerts";
@import "components/product-page";

html {
  font-size: 16px;
}

body {
  background: black;
  font-family: $font__sans-serif;
  color: $color__white;

  &.sus-cart-visible {
    overflow: hidden;
  }
}

div {
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.sus-wrapper {
  position: relative;
  z-index: 1;
}
.sus-content-wrapper {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}

.sus-grid-wrapper {
  widows: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1440px) {
    padding: 0 20px;
  }

  &--home {
    margin-top: -150px;
  }

  &--small {
    max-width: 800px;
  }
}

.sus-footer {
  background: rgba(white, 0.05);
  padding: 20px;
  text-align: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    li {
      margin: 0 20px;
    }
    a {
      color: white;
      font-size: 1.2rem;
      opacity: 0.5;
      transition: all 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }
  .sus-credit {
    font-family: $font__sans-serif;
    color: rgba(white, 0.2);
    margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 0.6rem;
    a {
      text-decoration: none;
      color: rgba(white, 0.5);
    }
  }
}

.sus-cart {
  .fa-times {
    padding: 10px;
    color: white;
    background: $color__red--dark;
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
    z-index: 20;
  }
  h2 {
    margin-top: 0;
  }
  &__panel {
    position: fixed;
    top: 0;
    right: 0px;
    width: 100%;
    max-width: 300px;
    height: 100%;
    background: $color__black;
    z-index: 9900;
    flex-wrap: wrap;
    padding: 15px;
    overflow-y: scroll;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: $color__red--dark;
    z-index: 400;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &__item {
    width: 100%;
  }

  &-item {
    &__image {
      img {
        width: 100px;
      }

      &--large {
        img {
          width: 250px;

          @include mq($until: md) {
            width: 100px;
          }
        }
      }
    }
    &::after {
      display: block;
      content: "";
      border-bottom: 2px dashed white;
      margin: 20px 0;
      opacity: 0.1;
    }
    & > div {
      display: flex;
    }

    &__details {
      padding: 15px;
      padding-top: 0;

      p + p {
        font-size: 0.7rem;
        opacity: 0.7;
        margin-top: 0;
      }
    }

    &__name {
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__quantity {
      margin-bottom: 15px;
    }

    &__price {
      span {
        font-size: 0.8rem;
        opacity: 0.7;
      }
    }

    button {
      color: white;
      background: $color__red--dark;
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 0;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.transition {
  &-cart-slide {
    z-index: 9999;
    position: relative;
    &-enter-active,
    &-leave-active {
      transition: all 0.3s ease-in-out;
      z-index: 9999;
      position: relative;
      .sus-cart__panel {
        transition: all 0.3s ease-in-out;
      }
    }

    &-enter,
    &-leave-to {
      z-index: 9999;
      position: relative;
      opacity: 0;
      .sus-cart__panel {
        right: -300px;
      }
    }
  }
}

.sus-form {
  max-width: 550px;
  margin: auto;
  .sus-alert {
    width: 100%;
    p {
      margin: 0;
    }
  }

  &__item {
    margin-bottom: 30px;
    label {
      display: block;
      padding-bottom: 5px;
    }

    input,
    textarea {
      box-sizing: border-box;
      background: rgba(white, 0.1);
      border: 0;
      width: 100%;
      outline: 0;
      color: white;
      padding: 10px 20px;
      transition: all 0.3s ease-in-out;
      &:focus {
        background: rgba(white, 0.2);
      }
    }

    textarea {
      min-height: 200px;
    }
  }

  &__captcha {
    margin: 20px 0 50px 0;
    font-size: 0.8rem;
    opacity: 0.8;
    a {
      color: white;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.lds-dual-ring {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flickity-page-dots {
  display: none !important;
}
