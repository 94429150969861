.sus-text {
  &--decor {
    font-family: $font__serif;
    letter-spacing: 6px;
    text-transform: uppercase;
    padding: 0px 5px;
  }
}
.sus-rgb-title {
  display: block;
  text-transform: uppercase;
  letter-spacing: 25px;
  font-size: 2rem;
  font-family: $font__serif;
  font-weight: normal;
  position: relative;
  span {
    position: relative;
    z-index: 3;
  }
  &::before {
    position: absolute;
    content: attr(data-text);
    z-index: 1;
    color: $color__rgb--red;
    right: 0;
    top: 0;
    left: 0;
    margin: auto;
    transform: translateX(-2px);
  }
  &::after {
    position: absolute;
    content: attr(data-text);
    z-index: 2;
    opacity: 0.7;
    color: $color__rgb--green;
    right: 0;
    top: 0;
    left: 0;
    margin: auto;
    transform: translateX(2px);
  }

  &--small {
    font-size: 1.6rem;
    letter-spacing: 17px;
  }

  @include mq($until: lg) {
    font-size: 1.4rem;
    letter-spacing: 15px;
  }
}

p {
  margin: 0 + p {
    margin-top: 20px;
  }
}

.sus-highlight {
  background: $color__red--dark;
  color: white;
  padding: 5px 15px;
  line-height: 1rem;
}

.sus-sale-tag {
  background: $color__rgb--red;
  padding: 5px 10px;
  border-radius: 5px;
}
