.sus-gig-list {
  margin-top: 100px;
  text-align: center;
  .sus-gig-list__item {
    background: rgba(white, 0.025);
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 0.7rem;
    align-items: center;
    &:hover {
      background: rgba(white, 0.05);
    }
  }

  &__title {
    padding: 0 10px;
  }
  a {
    display: inline-block;
  }
  .sus-gig-list__actions {
    margin: 40px 0 40px 0;
    text-align: center;
  }

  @include mq($until: md) {
    &__item {
      flex-direction: column;
      div {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
