.sus-pagination {
    text-align: center;
    margin: 20px 0 50px 0;
    .disabled {
        display: none; }
    a {
        display: inline-flex;

        width: 50px;
        height: 50px;
        color: white;
        background: rgba(white, 0.05);
        text-decoration: none;
        justify-content: center;
        display: inline-flex;
        span {
            display: flex;
            align-items: center; }
        &:hover {
            background: rgba(white, 0.1); } }
    .current {
        display: inline-flex;
        color: white;
        background: $color__red--dark;
        justify-content: center;
        width: 50px;
        height: 50px; }
    .previous {
        transform: translateY(-1px); }
    .next {
        transform: translateY(-1px); } }
