.sus-rgb-button {
  border: 3px solid white;
  padding: 20px 40px;
  font-family: $font__serif;
  color: white;
  letter-spacing: 7px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  appearance: none;
  background: none;
  outline: 0;

  &--disabled {
    text-decoration: line-through;
    cursor: not-allowed;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    background: rgba(white, 0.1);
  }
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    border: 3px solid $color__rgb--red;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 3px solid $color__rgb--green;
    z-index: -1;
    opacity: 0.7;
  }

  &--small {
    font-size: 0.6rem;
    padding: 15px 25px;
  }

  &--full-width {
    display: block;
    text-align: center;
  }
  .lds-dual-ring {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 0.3s ease-in-out;
  }

  &--loading {
    color: rgba(white, 0);
    .lds-dual-ring {
      opacity: 1;
    }
  }
}
