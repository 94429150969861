$marginSize: 30px;
$border: dashed 1px #ddd;

$font__serif: minion-pro, serif;
$font__sans-serif: gesta, sans-serif;

$color__black: #000;
$color__white: #fff;
$color__rgb--green: #28e50f;
$color__rgb--red: #ff0000;
$color__red--dark: #5c0404;

$mq-breakpoints: (
  sm: 320px,
  md: 740px,
  lg: 980px,
  xl: 1300px,
);

@import "~sass-mq";
